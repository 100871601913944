<template>
	<div
		class="modal fade"
		:id="id"
		tabindex="-1"
		role="dialog"
		aria-labelledby="InstrumentModal"
		aria-hidden="true"
	>
		<div class="modal-dialog" role="document">
			<div class="modal-content">
				<div class="modal-header">
					<h5 class="modal-title">Miembro de la colla</h5>
					<button
						@click="hideModal"
						class="close"
						type="button"
						data-dismiss="modal"
						aria-label="Close"
					>
						<span aria-hidden="true">×</span>
					</button>
				</div>
				<form @submit="saveData">
					<div class="modal-body">
						<div class="row">
							<div class="col-12 mb-2">
								<label class="required">Nombre</label>
								<input
									type="text"
									v-model="item.name"
									class="form-control"
								/>
							</div>
							<div class="col-12 mb-2">
								<instruments-combo
									ref="instrumentsCombo"
									@add="setInstrument"
									:current="current_instrument"
								/>
							</div>
						</div>
					</div>

					<div class="modal-footer">
						<button
							@click="hideModal"
							class="btn btn-dark"
							type="button"
							data-dismiss="modal"
						>
							{{ this.$t('buttons.cancel') }}
						</button>
						<button type="submit" class="btn btn-primary">
							{{ this.$t('buttons.save') }}
						</button>
					</div>
				</form>
			</div>
		</div>
	</div>
</template>
<script>
import InstrumentsCombo from '../Instruments/InstrumentsCombo';

export default {
	name: 'MemberFormModal',
	props: ['id', 'current'],
	components: {
		InstrumentsCombo
	},
	watch: {
		current: function (newItem) {
			if (!newItem) {
				this.item = { name: null };
				this.current_instrument = null;
			} else {
				this.item = { ...newItem };
				this.current_instrument = {
					id: newItem.instrument_id,
					name: newItem.instrument
				};
			}
		}
	},
	data() {
		return {
			current_instrument: null,
			item: {
				name: null,
				instrument_id: null
			}
		};
	},
	methods: {
		hideModal() {
			$(`#${this.id}`).modal('hide');
			this.$refs.instrumentsCombo.selected = null;
		},
		resetData() {},
		saveData(e) {
			e.preventDefault();
			axios.post('/api-admin/miembros', this.item).then((response) => {
				this.hideModal();
				this.resetData();
				this.$helpers.toastSuccess();
				this.$emit('saved', response);
			});
		},
		setInstrument(instrument) {
			this.item.instrument_id = instrument ? instrument.id : null;
		}
	}
};
</script>
