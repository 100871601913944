<template>
	<div>
		<v-select
			@input="add"
			v-model="selected"
			:multiple="multiple"
			:options="items"
			label="name"
			code="id"
		></v-select>
	</div>
</template>
<script>
import vSelect from 'vue-select';
import 'vue-select/dist/vue-select.css';
import { mapGetters } from 'vuex';

export default {
	name: 'MembersCombo',
	props: {
		current: { required: false },
		multiple: { default: false }
	},
	components: {
		vSelect
	},
	created() {
		if (this.api_auth) {
			this.setData();
		} else {
			this.$store.dispatch('global/getApiToken');
		}
	},
	watch: {
		current: function () {
			this.selected = { ...this.current };
		},
		api_auth: function (set) {
			if (set) {
				this.setData();
			}
		}
	},
	computed: {
		...mapGetters('global', ['api_auth'])
	},
	data() {
		return {
			selected: null,
			items: []
		};
	},
	methods: {
		setData() {
			axios
				.get('/api/miembros', { ...this.api_auth })
				.then((response) => {
					this.items = response.data;
				});
		},
		add() {
			const miembro = this.selected ? { ...this.selected } : null;
			this.$emit('add', miembro);
		}
	}
};
</script>
