var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:("VueTables VueTables--" + (_vm.props.source))},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-10"},[(!_vm.props.opts.filterByColumn && _vm.props.opts.filterable)?_c('div',{class:((_vm.props.theme.field) + " " + (_vm.props.theme.inline) + " " + (_vm.props.theme.left) + " VueTables__search")},[_c('vnodes',{attrs:{"vnodes":_vm.props.slots.beforeFilter}}),_vm._v(" "),_c('vt-generic-filter',{ref:"genericFilter"}),_vm._v(" "),_c('vnodes',{attrs:{"vnodes":_vm.props.slots.afterFilter}})],1):_vm._e(),_vm._v(" "),_c('vnodes',{attrs:{"vnodes":_vm.props.slots.afterFilterWrapper}}),_vm._v(" "),(
					(_vm.props.perPageValues.length > 1 ||
						_vm.props.opts.alwaysShowPerPageSelect) &&
					!_vm.props.opts.pagination.virtual
				)?_c('div',{class:((_vm.props.theme.field) + " " + (_vm.props.theme.inline) + " " + (_vm.props.theme.right) + " VueTables__limit")},[_c('vnodes',{attrs:{"vnodes":_vm.props.slots.beforeLimit}}),_vm._v(" "),_c('vt-per-page-selector'),_vm._v(" "),_c('vnodes',{attrs:{"vnodes":_vm.props.slots.afterLimit}})],1):_vm._e(),_vm._v(" "),(
					_vm.props.opts.pagination.dropdown && _vm.props.totalPages > 1
				)?_c('div',{staticClass:"VueTables__pagination-wrapper"},[_c('div',{class:((_vm.props.theme.field) + " " + (_vm.props.theme.inline) + " " + (_vm.props.theme.right) + " VueTables__dropdown-pagination")},[_c('vt-dropdown-pagination')],1)]):_vm._e(),_vm._v(" "),(_vm.props.opts.columnsDropdown)?_c('div',{class:("VueTables__columns-dropdown-wrapper " + (_vm.props.theme.right) + " " + (_vm.props.theme.dropdown.container))},[_c('vt-columns-dropdown')],1):_vm._e()],1)]),_vm._v(" "),_c('vnodes',{attrs:{"vnodes":_vm.props.slots.beforeTable}}),_vm._v(" "),_c('div',{staticClass:"table-responsive"},[_c('vt-table',{ref:"vt_table"}),_vm._v(" "),(_vm.props.opts.pagination.virtual && !_vm.props.loading)?_c('observer',{on:{"intersect":function($event){return _vm.props.setPage(_vm.props.page + 1)}}}):_vm._e()],1),_vm._v(" "),_c('vnodes',{attrs:{"vnodes":_vm.props.slots.afterTable}}),_vm._v(" "),(!_vm.props.opts.pagination.virtual && _vm.props.opts.pagination.show)?_c('vt-pagination'):_vm._e(),_vm._v(" "),(
			_vm.props.opts.pagination.virtual || _vm.props.opts.pagination.dropdown
		)?_c('vt-pagination-count'):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }