<template>
	<div class="row">
		<div
			:key="instrument.instrument_id"
			v-for="instrument in event.assist"
			class="col-12 mb-3 col-sm-6"
		>
			<table class="p-3 table table-bordered table-condensed mb-3">
				<thead>
					<tr>
						<th class="bg-secondary">
							{{ instrument.name }}
							<span
								class="float-right badge badge-dark badge-counter"
								>{{ instrument.members.length }}</span
							>
						</th>
					</tr>
				</thead>
				<tbody>
					<tr>
						<td>
							<ul class="row">
								<li
									class="col-6 col-sm-12"
									v-for="member in instrument.members"
								>
									{{ member.member_name }}
								</li>
							</ul>
						</td>
					</tr>
				</tbody>
			</table>
		</div>

		<div class="col-12 mb-3">
			<table class="p-3 table table-bordered table-condensed mb-3">
				<thead>
					<tr>
						<th class="bg-danger">
							NO ASISTIRÁN
							<span
								class="float-right badge badge-dark badge-counter"
								>{{ event.total_no_assist }}</span
							>
						</th>
					</tr>
				</thead>
				<tbody>
					<tr>
						<td>
							<ul class="row">
								<li
									class="col-6 col-sm-12"
									v-for="member in event.no_assist"
								>
									{{ member.member_name }}
								</li>
							</ul>
						</td>
					</tr>
				</tbody>
			</table>
		</div>
	</div>
</template>
<script>
export default {
	name: 'AttendDetail',
	props: {
		event: { required: true, type: Object }
	}
};
</script>
