<template>
	<div class="row">
		<div class="col-10 col-sm-4 custom-file">
			<input
				type="file"
				@change="setFile"
				class="custom-file-input"
				id="file"
			/>
			<label class="custom-file-label" for="file">{{ filename }}</label>
		</div>
		<div class="col-2 pt-1">
			<button @click="save" class="btn btn-sm btn-primary">
				<i class="fa fa-save"></i>
				{{ this.$t('buttons.save') }}
			</button>
		</div>
	</div>
</template>
<script>
export default {
	name: 'InputFileComponent',
	props: {
		entity_namespace: {
			type: String,
			required: true
		},
		entity_folder: {
			type: String,
			required: true
		},
		entity_id: {
			type: Number,
			required: true
		},
		refresh_table_fn: {
			type: Function
		},
		installation_id: {
			required: false
		}
	},
	data() {
		return {
			filename: '',
			file: null
		};
	},
	methods: {
		resetForm() {
			this.filename = '';
			this.file = null;
		},
		setFile(e) {
			const file = e.target.files[0];
			this.filename = file.name;
			this.file = file;
		},

		save() {
			let formData = new FormData();
			formData.append('file', this.file);
			formData.append('entity_namespace', this.entity_namespace);
			formData.append('entity_folder', this.entity_folder);
			formData.append('entity_id', this.entity_id);

			if (this.installation_id && this.installation_id !== 'undefined') {
				formData.append('installation_id', this.installation_id);
			}

			axios
				.post('/api-admin/attachments', formData, {
					headers: { 'content-type': 'multipart/form-data' }
				})
				.then((response) => {
					this.$helpers.toastSuccess();
					if (this.refresh_table_fn) {
						this.refresh_table_fn(response.data);
					}
					this.resetForm();
				});
		}
	}
};
</script>
<style scoped lang="scss">
.custom-file-input:lang(en) ~ .custom-file-label:after {
	content: '\f093'; /* inspect an icon for this value */
	font-family: FontAwesome; /* intentionally no fallback */
	font-size: 16px;
}
</style>
