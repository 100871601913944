<template>
	<card-template>
		<template #titleCard>
			Formulario de evento
			{{ event ? ` - ${event.title}` : null }}
		</template>
		<form @submit="save">
			<div class="row">
				<div class="col-12 col-sm-9 mb-2 form-group">
					<label class="required" for="">Título del evento</label>
					<input
						v-model="event.title"
						class="form-control"
						type="text"
					/>
				</div>
				<div class="col-12 col-sm-3 mb-2 form-group">
					<label class="required" for="">Día / Hora</label>
					<date-time-component
						:current-date="event.booking_js"
						@setDate="setDate"
					/>
				</div>
				<div class="col-12 mb-2 form-group">
					<label class="" for="">Descripción</label>
					<VueEditor
						v-model="event.description"
						:editor-toolbar="customToolbar"
					/>
				</div>
				<div class="col-12 mb-2 form-group">
					<label class="required" for="">Dirección</label>
					<input
						v-model="event.address"
						class="form-control"
						type="text"
					/>
				</div>
				<div class="col-12 form-group mb-2">
					<label class="" for="">Album de Google Fotos</label>
					<div class="input-group">
						<div class="input-group-prepend">
							<div class="input-group-text">
								<i class="fa fa-camera"></i>
							</div>
						</div>
						<input
							v-model="event.photos"
							class="form-control"
							placeholder="Link a Fotos"
							type="text"
						/>
					</div>
				</div>
			</div>
			<hr />
			<div class="row">
				<div class="col-12">
					<button
						class="float-right btn btn-success btn-sm ml-3"
						type="submit"
					>
						<i class="fas fa-save"></i> Guardar
					</button>
					<a class="float-right btn btn-danger btn-sm" href="/eventos">
						Cancelar
					</a>
				</div>
			</div>
		</form>
		<hr>
		<FileManager
			v-if="id"
			entity_namespace="App\Models\Event"
			entity_folder="events"
			:entity_id="id"
		/>
	</card-template>
</template>
<script>
import DateTimeComponent from '../Datepicker/DatepickerComponent';
import {VueEditor} from 'vue2-editor';
import FileManager from '/../Modules/Attachments/Resources/assets/components/FileManager.vue';

export default {
	name: 'EventForm',
	components: {
		DateTimeComponent,
		VueEditor,
		FileManager
	},
	props: {
		id: {required: false}
	},
	created() {
		if (this.id) this.getEvent();
	},
	data() {
		return {
			customToolbar: [
				[{
					header: [false, 1, 2, 3, 4, 5, 6]
				}],
				['bold', 'italic', 'underline'], // toggled buttons
				[{
					align: ''
				}, {
					align: 'center'
				}, {
					align: 'right'
				}, {
					align: 'justify'
				}],
				['blockquote', 'code'],
				[{
					list: 'ordered'
				}, {
					list: 'bullet'
				}, {
					list: 'check'
				}],
				[{
					color: []
				}, {
					background: []
				}], // dropdown with defaults from theme
				['link', 'image']
			],

			event: {
				title: null,
				description: null,
				address: null,
				booking: null,
				photos: null
			}
		};
	},
	methods: {
		getEvent() {
			axios.get(`/api-admin/eventos/${this.id}`).then((response) => {
				this.event = response.data;
				this.event.booking = response.data.booking_js;
			});
		},
		setDate(dateTime) {
			this.event.booking = dateTime;
		},
		save(e) {
			e.preventDefault();
			const event = {...this.event};
			event.id = this.id;
			console.log(event);
			axios.post('/api-admin/eventos', event).then((response) => {
				this.$helpers.toastSuccess();
				setTimeout(() => {
					window.location.href = '/eventos';
				}, 1000);
			});
		}
	}
};
</script>
