<template>
	<div v-if="api_auth" class="container-fluid">
		<h1>
			{{ event.title }}
			<span
				class="ml-3 float-right mr-3"
				v-html="event.photos_link"
			></span>
		</h1>

		<p class="fs-3"><i class="fa fa-clock"></i> {{ event.booking_full }}</p>
		<p class="fs-3"><i class="fa fa-building"></i> {{ event.address }}</p>
		<hr />
		<p v-html="event.description"></p>
		<hr />
		<button
			@click="setSubscription"
			style="width: 100%"
			class="btn btn-lg btn-secondary"
		>
			Inscribirse
		</button>
		<hr />
		<AttendSumary :event="event" />

		<hr />
		<button
			@click="showMembers"
			style="width: 100%"
			class="mb-3 btn btn-lg btn-info"
		>
			<i class="fa fa-users"></i>
			Ver Inscripciones
		</button>
		<a
			:href="link_calendar"
			style="width: 100%"
			class="mb-3 btn btn-lg btn-info"
			><i class="fa fa-calendar"></i> Calendario de Eventos</a
		>
		<hr />

		<subscription-modal
			@saved="getEvent"
			@deleted="getEvent"
			:id="modalId"
			:event="event"
		/>
		<event-mobile-members-modal :id="membersModalId" :event="event" />
	</div>
</template>
<script>
import SubscriptionModal from './SubscriptionModal';
import EventMobileMembersModal from './EventMobileMembersModal';
import AttendSumary from '../Events/AttendSumary.vue';
import { mapGetters } from 'vuex';
export default {
	name: 'EventMobile',
	props: {
		id: { required: true },
		link_calendar: { required: true }
	},
	components: {
		SubscriptionModal,
		EventMobileMembersModal,
		AttendSumary
	},
	async created() {
		await this.$store.dispatch('global/getApiToken');
		this.getEvent();
	},
	computed: {
		...mapGetters('global', ['api_auth'])
	},
	data() {
		return {
			event: {},
			modalId: 'SubscriptionModal',
			membersModalId: 'EventMembersModal',
			recaptcha: '',
			validateCaptcha: false
		};
	},
	methods: {
		getEvent() {
			axios
				.get(`/api/eventos/${this.id}`, { ...this.api_auth })
				.then((response) => {
					this.event = response.data;
				});
		},
		setSubscription() {
			$(`#${this.modalId}`).modal('show');
		},
		showMembers() {
			$(`#${this.membersModalId}`).modal('show');
		}
	}
};
</script>
