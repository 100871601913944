<template>
	<div
		class="modal fade"
		:id="id"
		tabindex="-1"
		role="dialog"
		aria-labelledby="EventMembers"
		aria-hidden="true"
	>
		<div class="modal-dialog" role="document">
			<div class="modal-content">
				<div class="modal-header">
					<h5 class="modal-title">Miembros inscritos</h5>
					<button
						@click="hideModal"
						class="close"
						type="button"
						data-dismiss="modal"
						aria-label="Close"
					>
						<span aria-hidden="true">×</span>
					</button>
				</div>

				<div class="modal-body">
					<AttendDetails :event="event" />
				</div>
				<button @click="hideModal" class="m-2 btn btn-sm btn-success">
					Cerrar
				</button>
			</div>
		</div>
	</div>
</template>
<script>
import AttendDetails from '../Events/AttendDetails.vue';
import { mapGetters } from 'vuex';
export default {
	name: 'EventMembers',
	props: {
		id: { required: true },
		event: { required: true }
	},
	components: {
		AttendDetails
	},
	computed: {
		...mapGetters('global', ['api_auth'])
	},

	methods: {
		hideModal() {
			$(`#${this.id}`).modal('hide');
		},

		copyLink(e) {
			e.preventDefault();
			if (!navigator.clipboard) {
				const element = this.$refs.eventLink;
				element.select();
				document.execCommand('copy');
				this.$toast.info(
					`Enlace copiado en el portapapeles: ${this.event.link}`
				);
				this.hideModal();
			} else {
				navigator.clipboard.writeText(this.event.link).then(() => {
					this.$toast.info(
						`Enlace copiado en el portapapeles: ${this.event.link}`
					);
				});
			}
		}
	}
};
</script>
