<template>
	<div id="Events">
		<item-event-calendar
			v-for="event in events"
			:key="event.id"
			:event="event"
		></item-event-calendar>
	</div>
</template>
<script>
import ItemEventCalendar from './ItemEventCalendar';
import { mapGetters } from 'vuex';

export default {
	name: 'ListadoEventos',
	components: {
		ItemEventCalendar
	},
	created() {
		this.getItems();
	},
	computed: {
		...mapGetters('global', ['api_auth'])
	},
	data() {
		return {
			events: []
		};
	},
	methods: {
		getItems() {
			axios.get('/api/eventos', { ...this.api_auth }).then((response) => {
				this.events = response.data;
			});
		}
	}
};
</script>
