<template>
	<div
		:id="id"
		class="modal fade"
		tabindex="-1"
		role="dialog"
		aria-labelledby="InstrumentModal"
		aria-hidden="true"
	>
		<div class="modal-dialog" role="document">
			<div class="modal-content">
				<div class="modal-header">
					<h5 class="modal-title">
						Instrumento
					</h5>
					<button
						class="close"
						type="button"
						data-dismiss="modal"
						aria-label="Close"
						@click="hideModal"
					>
						<span aria-hidden="true">×</span>
					</button>
				</div>
				<form @submit="saveData">
					<div class="modal-body">
						<div class="row">
							<div class="col-12 mb-2">
								<label class="required">Nombre del instrumento</label>
								<input
									v-model="item.name"
									type="text"
									class="form-control"
								/>
							</div>
							<div class="col-12 mb-2">
								<label for="IsInstrument">
									<input
										id="IsInstrument"
										v-model="item.is_instrument"
										type="checkbox"
										class="mr-2"
									>
									Es un intrumento de la banda
								</label>
							</div>
						</div>
					</div>

					<div class="modal-footer">
						<button
							class="btn btn-dark"
							type="button"
							data-dismiss="modal"
							@click="hideModal"
						>
							{{ this.$t('buttons.cancel') }}
						</button>
						<button type="submit" class="btn btn-primary">
							{{ this.$t('buttons.save') }}
						</button>
					</div>
				</form>
			</div>
		</div>
	</div>
</template>
<script>
export default {
	name: 'InstrumentFormModal',
	props: ['id', 'current'],
	watch: {
		current: function (newItem) {
			this.item = { ...newItem };
			if (!newItem) this.item = { name: null };
		}
	},
	data() {
		return {
			item: {
				name: null,
				is_instrument: true
			}
		};
	},
	methods: {
		hideModal() {
			$(`#${this.id}`).modal('hide');
		},
		resetData() {},
		saveData(e) {
			e.preventDefault();
			axios
				.post('/api-admin/instrumentos', this.item)
				.then((response) => {
					this.hideModal();
					this.resetData();
					this.$helpers.toastSuccess();
					this.$emit('saved', response);
				});
		}
	}
};
</script>
