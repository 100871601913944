<template>
	<card-template :show_header="false">
		<div class="table-responsive">
			<inno-core-datatable
				:idTable="idTable"
				:columnsData="columns"
				:rowItems="items"
				:customFields="customFields"
				@edit="editItem"
				@delete="deleteItem"
				@new="newItem"
			/>
		</div>
		<ModalConfirmDeleteFn />
		<MemberFormModal :id="modalId" :current="current" @saved="getItems" />
	</card-template>
</template>
<script>
import MemberFormModal from './MemberFormModal.vue';
export default {
	name: 'MembersList',
	components: { MemberFormModal },
	created() {
		this.getItems();
	},
	data() {
		return {
			current: null,
			modalId: 'MemeberFormModal',
			idTable: 'Members',
			items: [],
			customFields: [],
			columns: [
				{
					field: 'id',
					label: '#',
					display: 'min_tabletP'
				},
				{
					field: 'name',
					label: 'Nombre'
				},

				{
					field: 'instrument',
					label: 'Instrumento'
				},

				{
					field: 'options',
					label: 'Opciones'
				}
			]
		};
	},
	computed: {},
	methods: {
		getItems() {
			axios.get('/api-admin/miembros').then((response) => {
				this.items = response.data;
			});
		},
		editItem(item) {
			this.current = item;
			$(`#${this.modalId}`).modal('show');
		},
		deleteItem(item) {
			this.$bus.$emit('fireModalConfirmDeleteFn', {
				text: `Seguro de querer eliminar al miembro ${item.name}`,
				parameters: item,
				fn: this.removeItem
			});
		},
		removeItem(item) {
			axios.delete(`/api-admin/miembros/${item.id}`).then((response) => {
				this.items = response.data;
				this.$toast.success(this.$t('toast.delete_success'));
			});
		},
		newItem() {
			this.current = null;
			$(`#${this.modalId}`).modal('show');
		},
		saveCallback(items) {
			this.items = items;
		}
	}
};
</script>
