<template>
	<div class="p-3">
		<v-client-table
			ref="EventsTable"
			:data="events"
			:columns="columns"
			:options="options"
		>
			<div slot="options" slot-scope="{ row }" class="text-right">
				<button
					v-show="row.photos_url"
					class="btn btn-xs mb-1 btn-secondary text-light"
					v-html="row.photos_link"
				></button>
				<a
					:href="`/eventos/${row.id}`"
					class="btn btn-xs mb-1 btn-warning"
				><i class="fa fa-pencil"></i></a>
				<button
					class="btn btn-xs mb-1 btn-danger"
					@click="deleteItem(row)"
				>
					<i class="fa fa-trash"></i>
				</button>
				<a
					v-if="row.link"
					:title="row.link"
					:href="row.link"
					target="_blank"
					class="btn btn-xs mb-1 btn-success"
				>
					<i class="fa fa-share-alt"></i>
				</a>
			</div>

			<div slot="assist" slot-scope="{ row }">
				<div class="row">
					<div class="col-12 col-sm-10 mb-2">
						<p>
							<small><b>Total inscritos: {{ row.total_assist }}</b></small>
						</p>
						<span
							v-for="instrument in row.assist"
							:key="instrument.instrument_id"
						>
							<i>
								<small
									style="margin: 0 5px"
									class="text-gray-600"
								>
									{{ instrument.name }}:
									<b>{{ instrument.num }}</b>
								</small>
								|
							</i>
						</span>
					</div>
				</div>
			</div>
		</v-client-table>
		<ModalConfirmDeleteFn />
	</div>
</template>
<script>
export default {
	name: 'EventsTable',
	created() {
		this.setColumns();
		this.getData();
	},
	computed: {
		columnsData() {
			return [
				{
					field: 'title',
					label: 'Evento'
				},
				{
					field: 'booking',
					label: 'Fecha',
					dataClass: 'mw-50'
				},
				{
					field: 'assist',
					label: 'Asistencia',
					display: 'min_tabletP'
				},
				{
					field: 'options',
					label: 'Acciones'
				}
			];
		}
	},
	data() {
		return {
			events: [],
			columns: [],
			options: {
				sortable: [],
				resizableColumns: true,
				perPage: 10,
				perPageValues: [5, 10, 50, 100],
				pagination: { chunk: 5 },
				datepickerOptions: {
					showDropdowns: true,
					autoUpdateInput: true
				},
				sortIcon: {
					base: 'fa float-right',
					is: 'fa-sort',
					up: 'fa-sort-up',
					down: 'fa-sort-down'
				},
				texts: {
					count: `${this.$t(
						'Showing {from} to {to} of {count} records|{count} records|One record'
					)}`,
					first: `${this.$t('First')}`,
					last: `${this.$t('Last')}`,
					filter: `${this.$t('Filter')}:`,
					filterPlaceholder: `${this.$t('Search query')}`,
					limit: `${this.$t('Records')}:`,
					page: `${this.$t('Page')}:`,
					noResults: `${this.$t('No matching records')}`,
					filterBy: `${this.$t('Filter by {column}')}`,
					loading: `${this.$t('Loading')}...`,
					defaultOption: `${this.$t('Select {column}')}`,
					columns: `${this.$t('Columns')}`
				},
				columnsDisplay: {}
				// columnsDropdown: true,
				// columnsDisplay: {
				//     email: 'min_tabletP',
				//     id: 'min_tabletP'
				// },
				// selectable:{
				//     mode: 'multiple', // or 'multiple'
				//     only: function(row) {
				//         return true // any condition
				//     },
				//     selectAllMode: 'all', // or 'page',
				//     programmatic: false
				// }
			}
		};
	},
	methods: {
		setColumns() {
			const headings = {};
			this.columnsData.map((item) => {
				this.columns.push(item.field);
				headings[item.field] = item.label;
				if (item.display) {
					this.options.columnsDisplay[item.field] = item.display;
				}
			});
			this.options.headings = headings;
		},
		getData() {
			//this.$refs.EventsTable.setLoadingState(true)
			axios.get('/api-admin/eventos/history').then((response) => {
				this.events = response.data;
			});
		},

		deleteItem(event) {
			this.$bus.$emit('fireModalConfirmDeleteFn', {
				text: `Seguro de querer eliminar el evento ${event.title}`,
				parameters: event,
				fn: this.removeItem
			});
		},
		removeItem(event) {
			axios.delete(`/api-admin/eventos/${event.id}`).then((response) => {
				this.$toast.success(this.$t('toast.delete_success'));
				this.getData();
			});
		}
	}
};
</script>
<style lang="scss">
.text-light a {
	color: white;
}
.VueTables {
	label {
		margin-right: 10px;
	}
}

.VueTables__wrapper {
	max-height: 500px;
	overflow-y: scroll;
}

.VueTables__search-field {
	display: flex;
}

.VueTables__limit-field {
	display: flex;
}

.VueTables__columns-dropdown-wrapper {
	margin-right: 10px;
}

.VueTables__row {
	td {
		padding: 3px 5px;
		vertical-align: middle;
	}
}

.VueTables__columns-dropdown {
	input {
		margin-right: 3px;
	}
}

.mw-50 {
	max-width: 50px;
}
</style>
