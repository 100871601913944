<template>
	<div>
		<date-picker
			v-model="dateTime"
			@input="setDate"
			:lang="lang"
			format="DD/MM/YYYY HH:mm"
			type="datetime"
		/>
	</div>
</template>
<script>
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import 'vue2-datepicker/locale/es';

export default {
	name: 'DatePickerComponent',
	props: {
		currentDate: { required: false }
	},
	components: {
		DatePicker
	},
	watch: {
		currentDate: function () {
			if (this.currentDate) this.setCurrentDate();
		}
	},
	data() {
		return {
			dateTime: null,
			lang: {
				formatLocale: {
					firstDayOfWeek: 1
				}
			}
		};
	},
	methods: {
		setDate() {
			const formatDate = this.$moment(this.dateTime).format(
				'YYYY-MM-DD HH:mm:ss'
			);
			this.$emit('setDate', formatDate);
		},
		setCurrentDate() {
			const datePickerFormat = new Date(this.currentDate);
			this.dateTime = datePickerFormat;
		}
	}
};
</script>
