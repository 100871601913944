<template>
	<card-template :show_header="false">
		<div class="table-responsive">
			<inno-core-datatable
				:idTable="idTable"
				:columnsData="columns"
				:rowItems="items"
				:customFields="customFields"
				:btnConfig="true"
				iconConfig="fa-users"
				colorIconConfig="secondary"
				@edit="editItem"
				@delete="deleteItem"
				@new="newItem"
				@config="showMembers"
			/>
		</div>
		<ModalConfirmDeleteFn />
		<InstrumentFormModal
			:id="modalId"
			:current="current"
			@saved="getItems"
		/>
		<InstrumentMembersModal :id="modalMembersId" :current="current" />
	</card-template>
</template>
<script>
import InstrumentFormModal from './InstrumentFormModal.vue';
import InstrumentMembersModal from './InstrumentMembersModal.vue';
export default {
	name: 'InstrumentsList',
	components: {
		InstrumentFormModal,
		InstrumentMembersModal
	},
	created() {
		this.getItems();
	},
	data() {
		return {
			current: null,
			modalId: 'InstrumentFormMdoal',
			modalMembersId: 'InstrumentMembersModal',
			idTable: 'Instruments',
			items: [],
			customFields: [],
			columns: [
				{
					field: 'id',
					label: '#',
					display: 'min_tabletP'
				},
				{
					field: 'name',
					label: 'Instrumento'
				},

				{
					field: 'n_members',
					label: 'Asignados'
				},

				{
					field: 'options',
					label: 'Opciones'
				}
			]
		};
	},
	computed: {},
	methods: {
		getItems() {
			axios.get('/api-admin/instrumentos').then((response) => {
				this.items = response.data;
			});
		},
		editItem(item) {
			this.current = item;
			$(`#${this.modalId}`).modal('show');
		},
		deleteItem(item) {
			this.$bus.$emit('fireModalConfirmDeleteFn', {
				text: `Seguro de querer eliminar el instrumento ${item.name}`,
				parameters: item,
				fn: this.removeItem
			});
		},
		removeItem(item) {
			axios
				.delete(`/api-admin/instrumentos/${item.id}`)
				.then((response) => {
					this.items = response.data;
					this.$toast.success(this.$t('toast.delete_success'));
				});
		},
		newItem() {
			this.current = null;
			$(`#${this.modalId}`).modal('show');
		},
		saveCallback(items) {
			this.items = items;
		},
		showMembers(item) {
			this.current = item;
			$(`#${this.modalMembersId}`).modal('show');
		}
	}
};
</script>
