<template>
	<div>
		<div class="bg-secondary">
			<div class="row p-2">
				<div class="col-12 col-sm-8 mb-2">
					<span class="title">
						{{ event.title }}
					</span>
				</div>
				<div class="col-12 col-sm-4 mb-2">
					<div class="float-right">
						<small>
							<i class="fa fa-calendar"></i>
							{{ event.booking_full }}
						</small>
					</div>
				</div>
			</div>
		</div>

		<div class="row p-2 mb-2">
			<small
				class="d-block text-gray-600 col-12 col-sm-8 mb-3"
				v-html="event.description"
			></small>
			<div class="col-12 col-sm-4 d-print-none text-right">
				<button
					v-show="event.photos_url"
					class="btn btn-xs btn-secondary"
					@click="showAlbum"
				>
					<i class="fa fa-camera"></i>
				</button>
				<button class="btn btn-xs btn-warning" @click="editItem">
					<i class="fa fa-pencil"></i>
				</button>
				<button class="btn btn-xs btn-danger" @click="deleteItem">
					<i class="fa fa-trash"></i>
				</button>
				<button
					v-if="event.link"
					:title="event.link"
					class="btn btn-xs btn-success"
					@click="shareLink"
				>
					<i class="fa fa-share-alt"></i>
				</button>
			</div>
		</div>
	</div>
</template>
<script>
export default {
	name: 'EventAccordion',
	components: {},
	props: {
		event: { required: true }
	},
	computed: {
		cssPrint: function () {
			return `
					a{text-decoration:none}
					.no-print{display:none}
					table{font-size:11px}
					`;
		}
	},
	data() {
		return {
			printing: null,
			printObj: {
				id: `event-${this.event.id}`,
				preview: false,
				previewTitle: this.event.title, // The title of the preview window. The default is 打印预览
				popTitle: this.event.title
			}
		};
	},
	methods: {
		editItem(e) {
			e.preventDefault();
			window.location.href = `/eventos/${this.event.id}`;
		},
		showAlbum(e) {
			e.preventDefault();
			window.open(this.event.photos_url, '_blank');
		},
		deleteItem() {
			this.$bus.$emit('fireModalConfirmDeleteFn', {
				text: `Seguro de querer eliminar el evento ${this.event.title}`,
				parameters: null,
				fn: this.removeItem
			});
		},
		removeItem() {
			axios
				.delete(`/api-admin/eventos/${this.event.id}`)
				.then((response) => {
					this.$toast.success(this.$t('toast.delete_success'));
					this.$emit('deleted', true);
				});
		},
		printEvent() {
			this.printing.print(this.$el, [this.cssPrint]);
		},
		shareLink() {
			this.$emit('shareLink', this.event);
		}
	}
};
</script>
<style scoped>
.accordion-title {
	padding-top: 6px;
	border-radius: 5px;
}

.title {
}
</style>
