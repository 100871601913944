<template>
	<card-template :showHeader="true" padding="p-0">
		<template #titleCard>
			<div class="pt-1 row">
				<div class="col-12 mb-1 col-sm-10">
					<ul class="nav nav-pills">
						<li role="presentation" class="nav-item">
							<a
								data-toggle="tab"
								class="nav-link active"
								href="#NextList"
								>Próximos eventos</a
							>
						</li>
						<li role="presentation" class="nav-item">
							<a
								data-toggle="tab"
								class="nav-link"
								href="#HistoryTable"
								>Todos los eventos</a
							>
						</li>
					</ul>
				</div>
				<div class="col-12 col-sm-2 mb-1 text-right">
					<a
						href="/eventos/create"
						class="btn btn-sm btn-info float-right"
					>
						<i class="fa fa-plus"></i> Nuevo Evento
					</a>
				</div>
			</div>
		</template>

		<div class="tab-content">
			<div
				role="tabpanel"
				class="tab-pane fade show active"
				id="NextList"
			>
				<events-list></events-list>
			</div>
			<div role="tabpanel" class="tab-pane fade" id="HistoryTable">
				<events-table></events-table>
			</div>
		</div>
	</card-template>
</template>

<script>
import EventsList from './EventsList';
import EventsTable from './EventsTable';
export default {
	name: 'eventsTabs',
	components: {
		EventsList,
		EventsTable
	}
};
</script>
