<template>
	<div v-if="api_auth">
		<div class="container-fluid">
			<div class="pt-1 row">
				<div class="col-12">
					<ul class="nav nav-pills">
						<li role="presentation" class="nav-item">
							<a
								data-toggle="tab"
								class="nav-link active"
								href="#NextList"
								>Próximos eventos</a
							>
						</li>
						<li role="presentation" class="nav-item history-pill">
							<a
								data-toggle="tab"
								class="nav-link"
								href="#HistoryTable"
								><i class="fa fa-history"></i
							></a>
						</li>
					</ul>
				</div>
			</div>
		</div>

		<hr />
		<div class="tab-content">
			<div
				role="tabpanel"
				class="tab-pane fade show active"
				id="NextList"
			>
				<EventsCalendarList />
			</div>
			<div role="tabpanel" class="tab-pane fade" id="HistoryTable">
				<EventsCalendarTable class="p-1" />
			</div>
		</div>
	</div>
</template>
<script>
import EventsCalendarList from './EventsCalendarList';
import EventsCalendarTable from './EventsCalendarTable';
import { mapGetters } from 'vuex';
export default {
	name: 'EventsCalendarTab',
	async created() {
		await this.$store.dispatch('global/getApiToken');
	},
	components: {
		EventsCalendarList,
		EventsCalendarTable
	},
	computed: {
		...mapGetters('global', ['api_auth'])
	}
};
</script>

<style lang="scss" scoped>
.nav-pills {
	li.nav-item.history-pill {
		position: absolute;
		right: 0;
		a.nav-link.active {
			//background-color: transparent;
			//color: #282828;
		}
	}
}
</style>
