<template>
	<div
		class="modal fade"
		:id="id"
		tabindex="-1"
		role="dialog"
		aria-labelledby="SubscriptionModal"
		aria-hidden="true"
	>
		<div class="modal-dialog" role="document">
			<div class="modal-content">
				<div class="modal-header">
					<h5 class="modal-title">Inscripción al evento</h5>
					<button
						@click="hideModal"
						class="close"
						type="button"
						data-dismiss="modal"
						aria-label="Close"
					>
						<span aria-hidden="true">×</span>
					</button>
				</div>
				<form @submit="save">
					<div class="modal-body row">
						<div class="col-12 mb-2">
							<label for=""
								>Selecciona un nombre de la lista</label
							>
							<members-combo
								:current="member_data"
								@add="setMember"
							/>
						</div>

						<div class="col-12 my-2">
							<vue-toggles
								width="120"
								height="30"
								checkedText="Asistiré"
								uncheckedText="No Asistiré"
								checkedBg="#14a736"
								uncheckedBg="#fc7676"
								:value="item.attend"
								@click="item.attend = !item.attend"
							></vue-toggles>
						</div>

						<div class="col-12 mb-2">
							<label for=""
								>Selecciona el modo de inscripción</label
							>
							<div
								class="btn-group btn-group-toggle p-2 row"
								style="width: 100%"
								data-toggle="buttons"
							>
								<label
									class="col-12 mb-2 btn-first btn btn-info"
								>
									<input
										type="radio"
										v-model="memberType"
										name="options"
										id="option1"
										value="1"
										autocomplete="off"
									/>
									Portador
								</label>
								<label class="col-12 mb-2 btn btn-info">
									<input
										type="radio"
										v-model="memberType"
										name="options"
										id="option2"
										value="2"
										autocomplete="off"
									/>
									Acompañante
								</label>

								<label class="col-12 mb-2 btn btn-info">
									<input
										type="radio"
										v-model="memberType"
										name="options"
										id="option2"
										value="4"
										autocomplete="off"
									/>
									Niño/a
								</label>

								<label class="col-12 mb-2 btn btn-info">
									<input
										type="radio"
										v-model="memberType"
										name="options"
										id="option3"
										value="3"
										autocomplete="off"
									/>
									Músico
								</label>
							</div>
						</div>

						<div v-if="showInstrumentsCombo" class="col-12 mb-2">
							<hr />
							<label for=""
								>Selecciona el instrumento que llevarás</label
							>
							<instruments-combo
								@add="setInstrument"
								:current="this.currentInstrument"
							/>
						</div>
					</div>

					<ReCaptcha ref="Recaptcha" />

					<div class="modal-footer">
						<a
							v-show="current_subscription"
							@click="removeSubscription"
							class="btn btn-danger"
							><i class="fa fa-trash"></i> Borrar Inscripción</a
						>
						<button type="submit" class="btn btn-success">
							<i class="fa fa-save"></i> Guardar
						</button>
					</div>
				</form>
			</div>
		</div>
	</div>
</template>
<script>
import MembersCombo from '../Members/MembersCombo';
import InstrumentsCombo from '../Instruments/InstrumentsCombo';
import ReCaptcha from '../ReCaptcha';
import VueToggles from 'vue-toggles';
import { mapGetters } from 'vuex';

export default {
	name: 'SubscriptionModal',
	props: ['id', 'event'],
	components: {
		MembersCombo,
		InstrumentsCombo,
		ReCaptcha,
		VueToggles
	},
	computed: {
		...mapGetters('global', ['api_auth']),
		currentInstrument: function () {
			if (this.member_data) {
				return {
					id: this.member_data.instrument_id,
					name: this.member_data.instrument
				};
			}
		}
	},
	watch: {
		event: function () {
			if (this.event) {
				this.item.event_id = this.event.id;
			}
		},
		memberType: function (type) {
			this.item.member_type = type;
			this.showInstrumentsCombo = parseInt(type) === 3;
			if (parseInt(type) === 3) {
				this.item.instrument = this.member_data
					? this.member_data.instrument_id
					: null;
			} else {
				this.item.instrument = null;
			}
		}
	},
	data() {
		return {
			memberType: null,
			showInstrumentsCombo: false,
			member_data: null,
			current_subscription: null,
			item: {
				event_id: null,
				member: null,
				attend: true,
				instrument: null,
				member_type: null
			}
		};
	},
	methods: {
		resetForm() {
			this.memberType = null;
			this.showInstrumentsCombo = false;
			this.member_data = null;
			this.current_subscription = null;
			this.item = {
				event_id: null,
				attend: true,
				member: null,
				instrument: null,
				member_type: null
			};
		},
		hideModal() {
			$(`#${this.id}`).modal('hide');
		},
		setMember(member) {
			this.item.member = member ? member.id : null;
			this.member_data = member ? member : null;
			this.item.instrument =
				member && member.instrument_id ? member.instrument_id : null;
			this.getSubscription(member);
		},
		setInstrument(instrument) {
			this.item.instrument = instrument ? instrument.id : null;
		},
		setTypeSubscription() {},
		check() {
			const data = this.item;
			if (!data.member) {
				this.$toast.error(
					'los campos miembro y modo de inscripción son obligatorios'
				);
				return false;
			}

			if (!data.event_id) {
				this.$toast.error(
					'Hay un error al intentar recuperar la información del evento'
				);
				return false;
			}

			if (parseInt(data.member_type) === 3 && !data.instrument) {
				this.$toast.error(
					'Los músicos deben seleccionar un instrumento'
				);
				return false;
			}

			if (parseInt(data.member_type) !== 3) {
				this.item.instrument = null;
			}

			return true;
		},
		save(e) {
			e.preventDefault();
			if (!this.check()) return false;
			const parameters = { ...this.item };
			parameters.recaptcha_token = this.$refs.Recaptcha.token;
			axios
				.post('/api/eventos/subscription', parameters, {
					...this.api_auth
				})
				.then((response) => {
					this.$helpers.toastSuccess();
					this.$emit('saved', true);
					this.$refs.Recaptcha.reloadToken();
					this.resetForm();
					this.hideModal();
				});
		},
		getSubscription(member) {
			axios
				.get(`/api/eventos/${this.event.id}/member/${member.id}`, {
					...this.api_auth
				})
				.then((response) => {
					this.current_subscription = response.id || null;
				});
		},

		removeSubscription(e) {
			e.preventDefault();
			axios
				.delete(
					`/api/eventos/subscription/${this.current_subscription}`,
					{ ...this.api_auth }
				)
				.then((response) => {
					this.current_subscription = null;
					this.$helpers.toastDeletedSuccess();
					this.$emit('deleted', true);
					this.$refs.Recaptcha.reloadToken();
					this.resetForm();
					this.hideModal();
				});
		}
	}
};
</script>
<style scoped lang="scss">
label.btn {
	margin: 2px 5px;
}

label.btn.btn-first {
	margin-left: -2px;
}
</style>
