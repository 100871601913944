<template>
	<div>
		<card-template padding="p-0" :show_header="false">
			<div id="Events">
				<ul class="list-group">
					<li
						v-for="event in events"
						:key="event.id"
						class="list-group-item p-0"
					>
						<EventItemList
							:event="event"
							@shareLink="showShareModal"
							@deleted="getItems"
						/>
					</li>
				</ul>
			</div>
		</card-template>
		<ModalConfirmDeleteFn @deleted="getItems" />
		<event-share-modal :id="modalId" :event="currentShareEvent" />
	</div>
</template>
<script>
import EventShareModal from './EventShareModal';
import EventItemList from './EventItemList.vue';

export default {
	name: 'EventsList',
	components: {
		EventItemList,
		EventShareModal
	},
	created() {
		this.getItems();
	},
	data() {
		return {
			modalId: 'EventShareModal',
			currentShareEvent: {},
			events: []
		};
	},
	methods: {
		getItems() {
			axios.get('/api-admin/eventos').then((response) => {
				this.events = response.data;
			});
		},
		showShareModal(event) {
			this.currentShareEvent = event;
			$(`#${this.modalId}`).modal('show');
		}
	}
};
</script>
