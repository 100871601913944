<template>
	<div
		class="modal fade"
		:id="id"
		tabindex="-1"
		role="dialog"
		aria-labelledby="InstrumentMembers"
		aria-hidden="true"
	>
		<div class="modal-dialog" role="document">
			<div class="modal-content">
				<div class="modal-header">
					<h5 class="modal-title">
						Miembros de la colla con {{ item.name }}
					</h5>
					<button
						@click="hideModal"
						class="close"
						type="button"
						data-dismiss="modal"
						aria-label="Close"
					>
						<span aria-hidden="true">×</span>
					</button>
				</div>
				<div class="modal-body">
					<ul>
						<li v-for="(member, idx) in item.members" :key="idx">
							{{ member }}
						</li>
					</ul>
				</div>
				<div class="modal-footer">
					<button
						@click="hideModal"
						class="btn btn-dark"
						type="button"
						data-dismiss="modal"
					>
						Cerrar
					</button>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
export default {
	name: 'InstrumentsMembers',
	props: ['id', 'current'],
	watch: {
		current: function (newItem) {
			this.item = { ...newItem };
		}
	},
	data() {
		return {
			item: {
				name: null
			}
		};
	},
	methods: {
		hideModal() {
			$(`#${this.id}`).modal('hide');
		}
	}
};
</script>
