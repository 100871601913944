import Vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate';
import GlobalState from './global';

Vue.use(Vuex);

export default new Vuex.Store({
	modules: {
		global: GlobalState
	},
	plugins: [
		createPersistedState({
			storage: window.sessionStorage
		})
	]
});
