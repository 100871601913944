<template></template>
<script>
import { VueReCaptcha } from 'vue-recaptcha-v3';
export default {
	name: 'ReCaptcha',
	created() {
		this.loadRecaptcha();
	},
	data() {
		return {
			token: ''
		};
	},
	methods: {
		async loadRecaptcha() {
			Vue.use(VueReCaptcha, {
				siteKey: '6Lc5n-MjAAAAABbbLaDgZ_NJoqabS8v0sUhuQnHV'
			});
			// (optional) Wait until recaptcha has been loaded.
			await this.$recaptchaLoaded();
			const recaptcha = this.$recaptchaInstance;
			recaptcha.hideBadge();
			// Execute reCAPTCHA with action "login".
			this.reloadToken();
		},
		async reloadToken() {
			const token = await this.$recaptcha('login');
			this.token = token;
		}
	}
};
</script>
