<template>
	<div
		:id="id"
		class="modal fade"
		tabindex="-1"
		role="dialog"
		aria-labelledby="EventShareModal"
		aria-hidden="true"
	>
		<div class="modal-dialog" role="document">
			<div class="modal-content">
				<div class="modal-header">
					<h5 class="modal-title">Enlace para inscripciones</h5>
					<button
						class="close"
						type="button"
						data-dismiss="modal"
						aria-label="Close"
						@click="hideModal"
					>
						<span aria-hidden="true">×</span>
					</button>
				</div>

				<div class="col-12 mt-2">
					<label for="">Enlace</label>
					<input
						ref="eventLink"
						v-model="event.link"
						class="form-control"
						type="text"
					/>
				</div>

				<hr />
				<button class="m-2 btn btn-sm btn-success" @click="copyLink">
					<i class="fa fa-clipboard"></i> Copiar
				</button>
				<a
					:href="event.link"
					target="_blank"
					class="m-2 btn btn-sm btn-info"
					@click="hideModal"
					><i class="fa fa-link"></i> Abrir</a
				>
			</div>
		</div>
	</div>
</template>
<script>
export default {
	name: 'EventShareModal',
	props: {
		id: { required: true },
		event: { required: true }
	},

	methods: {
		hideModal() {
			$(`#${this.id}`).modal('hide');
		},

		copyLink(e) {
			e.preventDefault();
			if (!navigator.clipboard) {
				const element = this.$refs.eventLink;
				element.select();
				document.execCommand('copy');
				this.$toast.info(
					`Enlace copiado en el portapapeles: ${this.event.link}`
				);
				this.hideModal();
			} else {
				navigator.clipboard.writeText(this.event.link).then(() => {
					this.$toast.info(
						`Enlace copiado en el portapapeles: ${this.event.link}`
					);
				});
			}
		}
	}
};
</script>
