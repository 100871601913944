<template>
	<card-template class="eventCalendar" :show_header="false">
		<div @click="linkTo" class="row">
			<div class="col-12 col-sm-8 mb-2">
				<h4>{{ event.title }}</h4>
			</div>
			<div class="col-12 col-sm-4 mb-2">
				<div class="float-right">
					<i class="fa fa-calendar"></i> {{ event.booking_full }}
				</div>
			</div>
		</div>
	</card-template>
</template>
<script>
export default {
	name: 'ItemEventCalendar',
	props: {
		event: { required: true }
	},
	methods: {
		linkTo() {
			window.location.href = this.event.link;
		}
	}
};
</script>
<style lang="scss" scoped>
.eventCalendar {
	margin-bottom: 5px !important;
	cursor: pointer;

	&:hover {
		box-shadow: 0 0 3px #000 !important;
	}
}
</style>
