<template>
	<div>
		<ul class="row">
			<li
				class="col-6"
				v-for="instrument in event.assist"
				:key="instrument.instrument_id"
			>
				<span>
					<i>
						<small class="text-gray-600">
							{{ instrument.name }}: <b>{{ instrument.num }}</b>
						</small>
					</i>
				</span>
			</li>
		</ul>
		<h6 class="text-right">
			<span class="mr-3"
				>Total Asistencias: <b>{{ event.total_assist }}</b></span
			>
			<span class="mr-3"
				>Total No Asistencias: <b>{{ event.total_no_assist }}</b></span
			>
			<span class="mr-3"
				>Total: <b>{{ event.total }}</b></span
			>
		</h6>
	</div>
</template>
<script>
export default {
	name: 'AttendSummary',
	props: {
		event: { required: true, type: Object }
	}
};
</script>
