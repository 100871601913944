<template>
	<v-client-table
		@row-click="eventSelected"
		ref="EventsTable"
		:data="events"
		:columns="columns"
		:options="options"
	>
	</v-client-table>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
	name: 'EventsCalendarTable',
	created() {
		this.setColumns();
		this.getData();
	},
	computed: {
		...mapGetters('global', ['api_auth']),
		columnsData() {
			return [
				{
					field: 'title',
					label: 'Evento'
				},
				{
					field: 'booking',
					label: 'Fecha',
					dataClass: 'mw-50'
				}
			];
		}
	},
	data() {
		return {
			events: [],
			columns: [],
			options: {
				sortable: [],
				resizableColumns: true,
				perPage: 10,
				perPageValues: [5, 10, 50, 100],
				pagination: { chunk: 5 },
				datepickerOptions: {
					showDropdowns: true,
					autoUpdateInput: true
				},
				sortIcon: {
					base: 'fa float-right',
					is: 'fa-sort',
					up: 'fa-sort-up',
					down: 'fa-sort-down'
				},
				texts: {
					count: `${this.$t(
						'Showing {from} to {to} of {count} records|{count} records|One record'
					)}`,
					first: `${this.$t('First')}`,
					last: `${this.$t('Last')}`,
					filter: `${this.$t('Filter')}:`,
					filterPlaceholder: `${this.$t('Search query')}`,
					limit: `${this.$t('Records')}:`,
					page: `${this.$t('Page')}:`,
					noResults: `${this.$t('No matching records')}`,
					filterBy: `${this.$t('Filter by {column}')}`,
					loading: `${this.$t('Loading')}...`,
					defaultOption: `${this.$t('Select {column}')}`,
					columns: `${this.$t('Columns')}`
				},
				columnsDisplay: {}
			}
		};
	},
	methods: {
		setColumns() {
			const headings = {};
			this.columnsData.map((item) => {
				this.columns.push(item.field);
				headings[item.field] = item.label;
				if (item.display) {
					this.options.columnsDisplay[item.field] = item.display;
				}
			});
			this.options.headings = headings;
		},
		getData() {
			axios
				.get('/api/eventos/history', { ...this.api_auth })
				.then((response) => {
					this.events = response.data;
				});
		},
		eventSelected(event) {
			const link = event.row.link;
			if (link) {
				window.location.href = link;
			}
		}
	}
};
</script>
